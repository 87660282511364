import React, { useEffect, useRef } from "react";
import tw, { styled, css } from "twin.macro";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import { HiArrowNarrowRight } from "react-icons/hi";
import Title from "./Title";
import Button from "./Button";
import FHLogoSlider from "./FHLogoSlider";

const Hero = styled.section`
  ${tw`relative flex flex-col items-center justify-end w-full min-h-screen pb-12 text-white bg-transparent pt-36 sm:pt-0`}

  .background-image {
    ${tw`absolute top-0 left-0 w-full h-full z-behind`}
  }

  .fade-up {
    ${tw`opacity-0 translate-y-[2rem]`}

    &.active {
      animation: fade-up 5s ease-in-out forwards;
    }
  }

  @keyframes fade-up {
    15% {
      opacity: 0;
      transform: translateY(2rem);
    }

    30%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Wrapper = tw.div`flex relative flex-col items-center pt-20 md:pt-52 text-center justify-end h-full max-w-[50rem]`;

const Body = tw.p`text-center text-lg sm:text-xl px-4 md:text-2xl leading-relaxed mb-12`;

const PreSponsor = tw.p`mt-16 sm:mt-24 mb-10 relative max-w-[20rem] md:(max-w-none text-xl) text-center uppercase px-4 font-din before:(content[''] hidden sm:flex absolute h-px w-20 bg-white right-0 my-auto top-0 bottom-0 translate-x-full) after:(content[''] hidden sm:flex absolute h-px w-20 bg-white left-0 my-auto top-0 bottom-0 -translate-x-full)`;

const Video = tw.video`absolute top-0 left-0 w-full h-full z-behind object-cover brightness-50`;
const VideoOverlay = tw.div`absolute top-0 left-0 w-full h-full bg-darkAlt opacity-10 z-behind`;

const PostSponsor = tw(
  Link
)`text-center text-bright uppercase text-base font-din flex flex-row gap-x-2 items-center`;

const FrontHero = () => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsFrontHero {
          frontHero {
            video: fhBackgroundVideoSource
            button: fhButton {
              ...Button
            }
            content: fhContent
            sponsorTY: fhSponsorThankYou
            title: fhTitle
            viewSponsors: fhViewSponsors {
              ...Button
            }
            background: fhBackgroundImage {
              ...GatsbyImage_Blur
            }
            logoSlider: fhLogoSlider {
              logo {
                ...GatsbyImage_Blur
              }
            }
          }
        }
      }
    }
  `);
  const {
    video,
    button,
    content,
    sponsorTY,
    title,
    viewSponsors,
    background,
    logoSlider,
  } = wp?.acfOptionsFrontHero?.frontHero;

  const { observer, inView } = useInView({ triggerOnce: true });
  const newRef = useRef();
  useEffect(() => {
    const elements = document.querySelectorAll(".fade-up");
    for (let i = 0; i < elements.length; i++) {
      setTimeout(() => {
        elements[i].classList.add("active");
      }, i * 300);
    }
  }, [inView]);

  return (
    <Hero ref={observer}>
      <Wrapper>
        <Title hero tw="mb-10">
          {title}
        </Title>
        <Body className="fade-up">{content}</Body>
        <Button className="fade-up" {...button} />
        <PreSponsor className="fade-up ">{sponsorTY}</PreSponsor>

        <FHLogoSlider logoSlider={logoSlider} />
        <PostSponsor className="fade-up " to={viewSponsors.url}>
          <span tw="border-b border-primary">{viewSponsors.title}</span>
          <HiArrowNarrowRight tw="text-2xl" />
        </PostSponsor>
      </Wrapper>
      {background?.localFile && !video && (
        <GatsbyImage
          className="background-image"
          image={getImage(background.localFile)}
          alt={background.altText}
        />
      )}
      {video && (
        <>
          <Video
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            id="vid"
            width="100%"
            height="100%"
          >
            <source src={video} type="video/mp4" />
          </Video>
          <VideoOverlay />
        </>
      )}
    </Hero>
  );
};

export default FrontHero;
