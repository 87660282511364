import React, { useEffect } from "react";
import tw, { styled } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import anime from "animejs";
import { useInView } from "react-intersection-observer";

import Title from "./Title";

const Item = styled(Link)`
  ${tw`flex flex-col items-center justify-start text-center opacity-100 translate-y-[50px] max-w-full`}

  .gatsby-image-wrapper {
    ${tw`mb-2 duration-500 ease-in-out`}
  }
  &:hover {
    .gatsby-image-wrapper {
      ${tw`translate-y-[-.5rem] scale-105`}
    }
    .title {
      ${tw`after:(bg-secondary!)`}
    }
  }
`;
const Icon = tw.div`max-w-[15rem]`;

const AOPSingle = ({ icon, title, description, link, ...rest }) => {
  const { ref: observer, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      anime({
        targets: ".single-aop",
        translateY: [50, 0],
        opacity: ["0%", "100%"],
        duration: 1250,
        easing: "easeInOutCubic",
      });
    }
  }, [inView]);

  return (
    <Item to={link.url} ref={observer} className="single-aop">
      <Icon>
        <GatsbyImage image={getImage(icon.localFile)} alt={icon.altText} />
      </Icon>
      <Title className="title" tw="after:(bg-secondaryLight w-[90%]) mb-8">
        {title}
      </Title>
      <p tw="text-lg">{description}</p>
    </Item>
  );
};

export default AOPSingle;
