import React, { useState, useRef, useEffect } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import tw, { styled, css } from "twin.macro";
import anime from "animejs";
import { useInView } from "react-intersection-observer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Logo = tw.div`relative h-full my-auto flex items-center justify-center max-w-[16rem] mb-6 [.gatsby-image-wrapper]:(h-full flex items-center justify-center my-auto)`;

const Section = styled.div(({ sidebar }) => [
  tw`flex items-center justify-center max-w-[16rem] [.slide]:(h-full flex items-center justify-center)`,
  css`
    .fade-up {
      ${tw`opacity-0 translate-y-[2rem]`}

      &.active {
        animation: fade-up 5s ease-in-out forwards;
      }
    }

    @keyframes fade-up {
      15% {
        opacity: 0;
        transform: translateY(2rem);
      }

      30%,
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `,
]);
const Slider = styled(Swiper)(({ GZ }) => [tw`text-white `]);
const FHLogoSlider = ({ logoSlider }) => {
  const { ref: observer, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const elementRef = useRef();

  useEffect(() => {
    if (inView) {
      anime({
        targets: elementRef.current,
        delay: 500,
        opacity: ["0%", "100%"],
        duration: 1250,
        easing: "easeInOutCubic",
      });
    }
  }, [inView]);

  return (
    <Section className="fade-up">
      <Slider
        ref={elementRef}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        {logoSlider.map(({ logo }, i) => {
          return (
            <SwiperSlide className="slide" key={i}>
              <Logo>
                <GatsbyImage
                  image={getImage(logo.localFile)}
                  alt={logo.altText}
                />
              </Logo>
            </SwiperSlide>
          );
        })}
      </Slider>
    </Section>
  );
};

export default FHLogoSlider;
