import React from "react";
import tw, { styled, css } from "twin.macro";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from "./Button";

const Section = tw.section`flex items-center justify-center py-12 px-4 lg:(px-8 py-36)`;
const List = tw.ul`grid grid-cols-1 max-w-[40rem] lg:(grid-rows-2 grid-cols-2 grid-flow-col max-w-[90rem]) justify-between items-stretch gap-4 lg:gap-6`;

const Single = styled(Link)(({ tall }) => [
  tw`relative flex flex-col items-center justify-center md:(items-end justify-end) overflow-hidden`,
  tall && tw`lg:row-span-full`,
  css`
    .gatsby-image-wrapper {
      ${tw`w-full h-full duration-500 ease-in-out z-behind`}
      img {
        ${tw`object-cover! h-full! w-full! `}
      }
    }
    &:hover {
      .gatsby-image-wrapper {
        ${tw`md:(brightness-110 scale-105)`}
      }
    }
  `,
]);

const Card = ({ image, link, tall, ...rest }) => {
  return (
    <Single {...rest} tall={tall} to={link.url}>
      <GatsbyImage image={getImage(image.localFile)} alt={image.altText} />
      <Button
        title={link.title}
        outline
        tw="absolute px-0 my-auto md:(text-lg mb-4 m-8 px-16 w-auto) text-sm w-[calc(100% - 2rem)]"
      />
    </Single>
  );
};

const FrontImageLinks = ({ ...rest }) => {
  const { boxes } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsImageLinks {
          imageLinks {
            boxes: ilBoxes {
              image {
                ...GatsbyImage_Blur
              }
              link {
                ...Button
              }
            }
          }
        }
      }
    }
  `).wp.acfOptionsImageLinks.imageLinks;
  return (
    <Section {...rest}>
      <List>
        {boxes.map((box, i) => (
          <Card {...box} key={i} index={i} tall={i === 0} />
        ))}
      </List>
    </Section>
  );
};

export default FrontImageLinks;
