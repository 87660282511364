import React from "react";
import tw from "twin.macro";

import Button from "./Button";

const Section = tw.section`w-full flex relative flex-col sm:flex-row items-center py-12 justify-evenly gap-12 sm:(gap-4 py-20) bg-gradient-to-br from-[#2c576a] to-[#06282a] px-6  md:(gap-32 justify-center)`;
const Title = tw.h3`text-center text-white text-3xl font-din`;

const FrontCTA = ({ title, link, ...rest }) => {
  return (
    <Section {...rest}>
      <Title>{title}</Title>
      <Button secondary {...link} />
    </Section>
  );
};

export default FrontCTA;
