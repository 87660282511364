import React, { useEffect, useRef } from "react";
import tw, { styled } from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Slider from "./SponsorSlider";

const Section = tw.section`flex relative flex-row min-h-[30rem] md:mb-[15rem] lg:mb-[10rem] max-w-full xl:mb-0`;
const Left = styled.div`
  ${tw`xl:justify-center md:flex relative flex-col items-start justify-start hidden mb-24`}
  .gatsby-image-wrapper {
    ${tw`h-full! `}
    img {
      ${tw`object-cover! h-full! object-[10%]`}
    }
  }
`;
const ContentWrapper = tw.div`absolute hidden md:(ml-[20rem] flex mt-12) lg:(ml-[25rem] ) xl:ml-[25rem]  flex-row pt-10 items-end pr-12 xl:pr-48`;

const Right = tw.div`bg-darkAlt w-full top-0 left-0 right-0 md:(left-auto top-auto  max-w-[30rem] bottom-[8rem] right-[4rem]) lg:bottom-[12rem] mx-auto xl:(relative top-0 right-0 max-w-[25rem]) 2xl:max-w-[30rem] absolute border-b-[4rem] border-white`;

const Overview = ({ ...rest }) => {
  const { wp } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsOverview {
          overview {
            background: oBackground {
              ...GatsbyImage_Blur
            }
          }
        }
      }
    }
  `);
  const { background } = wp?.acfOptionsOverview?.overview;

  return (
    <Section {...rest}>
      <Left>
        <GatsbyImage
          image={getImage(background.localFile)}
          alt={background.altText}
        />
        <ContentWrapper>
          <p tw="font-bold text-xl flex flex-row items-center after:(content[''] bg-font w-20 mx-4 h-px)">
            EST.
          </p>

          <p tw="max-w-[32rem] text-xl">
            Established in 1988, PBCJA has grown exponentially and is now
            comprised of more than 500 members, including attorneys and
            paralegals.
          </p>
        </ContentWrapper>
      </Left>
      <Right>
        <Slider />
      </Right>
    </Section>
  );
};

export default Overview;
