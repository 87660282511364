import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import FrontHero from "../components/FrontHero";
import Overview from "../components/Overview";
import AOPs from "../components/AOPs";
import FrontCTA from "../components/FrontCTA";
import FrontImageLinks from "../components/FrontImageLinks";

const IndexPage = ({ data }) => {
  if (!data.wpPage) {
    throw new Error(
      "Home page query came back empty. Do you have a home page set in WordPress?"
    );
  }
  const { cta1 } = data.wp.acfOptionsCta1;
  const { cta2 } = data.wp.acfOptionsCta2;

  return (
    <Layout seo={data.wpPage.seo}>
      <FrontHero />
      <Overview />
      <AOPs />
      <FrontCTA {...cta1} />
      <FrontImageLinks />
      <FrontCTA {...cta2} />
    </Layout>
  );
};

export const pageQuery = graphql`
  # The page query for the front page's information.
  query {
    wpPage(isFrontPage: { eq: true }) {
      title
      ...SEO
    }

    wp {
      acfOptionsCta1 {
        cta1 {
          title: cta1Title
          link: cta1Link {
            ...Button
          }
        }
      }
      acfOptionsCta2 {
        cta2 {
          title: cta2Title
          link: cta2Link {
            ...Button
          }
        }
      }
    }
  }
  # Fragment for Yoast's SEO data on pages
  fragment SEO on WpPage {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }

  # Fragment for Yoast's SEO data on posts
  fragment PostSEO on WpPost {
    seo {
      metaDesc
      metaKeywords
      metaRobotsNofollow
      metaRobotsNoindex
      opengraphAuthor
      opengraphDescription
      opengraphPublisher
      opengraphSiteName
      opengraphTitle
      opengraphType
      opengraphUrl
      opengraphImage {
        sourceUrl
      }
      schema {
        raw
      }
      title
      twitterDescription
      twitterTitle
      twitterImage {
        sourceUrl
      }
    }
  }

  # Fragment for link buttons
  fragment Button on WpAcfLink {
    url
    target
    title
  }

  # Fragment for Gatsby images from media library
  fragment GatsbyImage_Blur on WpMediaItem {
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 90)
      }
    }
    altText
  }
  fragment GatsbyImage_SVG on WpMediaItem {
    localFile {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, quality: 90)
      }
    }
    altText
  }
`;

export default IndexPage;
