import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";

import Tile from "./AOPSingle";

const Section = tw.section`flex items-center relative justify-center py-36 px-6 w-full`;
const List = tw.ul`flex flex-col relative lg:(flex-row items-stretch) justify-center items-center w-full max-w-[70rem] gap-20 [li]:(max-w-[25rem] lg:(w-1/3 max-w-none))`;

const AOPs = ({ ...rest }) => {
  const { tiles } = useStaticQuery(graphql`
    {
      wp {
        acfOptionsTiles {
          tiles {
            tiles: tTiles {
              description
              icon {
                ...GatsbyImage_Blur
              }
              link {
                ...Button
              }
              title
            }
          }
        }
      }
    }
  `).wp.acfOptionsTiles.tiles;
  return (
    <Section {...rest}>
      <List>
        {tiles.map((tile, i) => (
          <li key={i}>
            <Tile {...tile} />
          </li>
        ))}
      </List>
    </Section>
  );
};

export default AOPs;
